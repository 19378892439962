.profile-avatar{
    height: 300px;
    width: 300px;
    border-radius: 50%;
    object-fit: cover;
}

.profile-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.center-container{
    justify-content: center;
}

.members-container{
    align-items: center;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    margin: 10px;
    padding: 5px;
    background-color: white;

    img{
        border-radius: 50%;
        height: 40px;
        margin-right: 20px;
        object-fit: cover;
        width: 40px;
    }

    .members-container-delete-button{
        margin-left: auto;
        margin-right: 10px;
    }
}