.organisations-overview-logo{
    width: 100px;
}

.show-rapport-button{
    cursor: pointer;
}

.report-banner {
    height: 200px;
    width: 100%;
    object-fit: cover;
}

.report-logo {
    padding: 40px;
    background-color: white;
    margin-top: -50px;
    width: 10%;
    margin-left: 20px;
    border-radius: 5px;
}

.report-title {
    text-align: left;
    margin-left: 40px;
}

.report-container {
    background-color: white;
    border-radius: 5px;
}

.report-inner-container {
    margin: 20px 40px 40px 40px;
    padding-bottom: 50px;
}

.report-section-container-contrast {
    margin: 50px -40px;
    background-color: #47acc3;
    padding: 40px 40px;

    h2 {
        color: white;
    }

    h3 {
        color: white;
        margin-left: 40px;
    }
}

.report-section-title-container {
    display: flex;
    align-items: center;

    img{
        margin-right: 10px;
        height: 30px;
    }

    svg{
        margin-right: 10px;
        height: 30px;
    }
}

.report-targetgroup-container {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
}

.report-targetgroup-item-container {
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    width: 100px;
    margin: 20px 40px 0px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
}

.report-activity-item-container {
    border-bottom: 1px solid lightgray;
    padding-bottom: 20px;
}

#organisation-detail-theme-pairs-container{
    margin-left: 30px;

    h3{
        margin-left: 0px;
    }
}

#organisations-themes-container{
    cursor: pointer;
}

#compagnies-them-table-cell-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}