.custom-tooltip{
    background-color: white;
    padding: 5px 20px;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
}

#graph-loading-container{
    width: 90%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(242, 242, 242);
    border-radius: 5px;
}