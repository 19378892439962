body {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

#layout-container{
    display: flex;
    width: 100%;
    min-height: 100vh;
}

#page-outer-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    padding: 0px 30px 30px 30px;
    background-color: #fafbff;
}

#user-profile-container{
    display: flex;
    align-items: center;
    margin-right: 60px;

    img{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
    }

    p{
        margin-left: 10px;
        cursor: pointer;
    }
}

.card-container{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 10px;
}

.card{
    width: 250px;
    min-height: 400px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2{
        text-align: center;
    }

    .sdg-card-meta-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .card-sdg-banner {
        width: 100%!important;
        height: 100px!important;
        border-radius: 0!important;
        box-shadow: none!important;
        object-fit: cover;
    }

    .organisations-logo {
        max-width: 80%!important;
        margin: 20px;
        border-radius: 0!important;
        box-shadow: none!important;
        max-height: 50px;
    }

    .card-button-container{
        margin: auto 30px 30px 30px;
    }
}

.page-container{
    margin-top: 10px;
}

.banner-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banner{
    width: 80%;
    min-height: 50px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
    padding: 20px;
    margin: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-user-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    img{
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 50%;
    }
}

.page-top-container{
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    border-bottom: 1px solid rgb(242, 242, 242);

    button{
        margin-bottom: 20px;
    }

    .page-header-title-container{
        display: flex;
        align-items: center;
        margin: 10px 10px 20px 10px;
    
        svg{
            height: 45px;
            width: 45px;
            margin-right: 15px;
            fill: #2F2C41;
        }
      }

      .page-edit-icon{
        height: 25px;
        width: 25px;
        margin: 0px 20px;
        cursor: pointer;
      }
}

.notifications-user-container{
    display: flex;
    align-items: center;
    cursor: pointer;

    img{
        height: 50px;
        width: 50px;
        border-radius: 50%;
        margin-right: 10px;
    }
}

.topbar-icon-inner-container{
    display: flex;
    align-items: center;

    p{
        margin: -10px 0px 10px -15px;
        background-color: green;
        color: white;
        border-radius: 50%;
        padding: 2px;
        font-size: 12px;
        width: 12px;
        height: 12px;
        text-align: center;
    }
}

.icon-container{
    display: flex;
    align-items: center;
}

.author-options-container{
    margin-left: auto;
    margin-right: 20px;
}

.edit-message-container{
    display: flex;
    align-items: center;

    input{
        margin-right: 10px;
    }
}

#upload-file-input{
    margin-left: 33px;
}

.dropdown-container-profile{
    margin-left: -40px;
}

.line-div{
    height: 1px;
    width: 100%;
    background-color: lightgrey;
}

.inner-page-container{
    background-color: white;
    width: 100%;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
}

.table-container{
    width: 95%;
    overflow-x: auto;
    margin: 20px 0px;
}

table {
    width: 100%;
    background-color: #fff;

    th {
        padding: 10px;
        text-align: left;
        background-color: #63cadc;
        color: #fff;
    }

    td {
        margin-right: 10px;
        border: 1px solid lightgray;
        padding: 2px 5px;

        img{
            height: 20px;
            cursor: pointer;
        }
    }

    p{
        margin-left: 10px;
    }

    input, select, textarea {
        border: none;
        background: transparent;
        box-shadow: none;
    }

    textarea{
        min-height: 150px;
        width: 98%;
        padding: 10px;
    }
}

.table-delete-icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-left: 10px;
}

.recharts-text {
    font-size: 15px;
    font-weight: bold;
}

.milestone-progress-container {
    margin: 20px 0 0 30px;
}

.percentage-container {
    display: flex;
    justify-content: space-between;
}

.progressbar-outer-bar {
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.progressbar-progress {
    padding: 5px;
}

.tooltip-container {
    position: relative;
    display: inline-block;
    margin: 0px;

    p{
        color: white;
        font-size: 12px;
    }
  }
  
  .tooltip {
    position: absolute;
    z-index: 1;
    background-color: black;
    color: white;
    padding: 8px;
    border-radius: 5px;
    left: 0;
    font-size: 12px;
  }

  .tooltip-trigger{
    margin: 0px;
  }

  .table-icon{
    cursor: pointer;
    margin-top: 10px;
  }

  .sidebar-section{
    margin-bottom: 30px;
  }

  #no-proof-container{
    background-color: white;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    padding: 20px;

    p{
        text-align: center;
    }
  }

  #modal-button-container {
    display: flex;
    justify-content: end;
    background-color: #f4f4f4;
    padding: 20px;
    margin: 50px 0px 20px -20px;

    button{
        border-radius: 5px;
        margin-left: 20px;
    }

    #modal-cancel-button{
        background-color: #ff0000;
        border: 1px solid #ff0000;
    }
}

#modal-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.input-checkbox-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;

    input{
        margin-right: 10px;
        width: 10px;
        box-shadow: none;
    }
}

.section-container{
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    margin-right: 20px;
    border: 1px solid rgb(242, 242, 242);
}

.modal-container{
    overflow: scroll;
}

.add-item-button{
    padding: 10px;
    border-radius: 5px;
    background-color: rgb(244, 244, 244);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;

    svg{
        margin-right: 5px;
        width: 20px;
        height: 20px;
    }

    img{
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
}

.add-icon-button-container{
    display: flex;
    margin-bottom: 10px;
}

.add-icon-container{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: flex-start;
}

.add-icon{
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.ReactModal__Overlay, .ReactModal__Overlay--after-open{
    overflow-x: scroll;
}

.add-item-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: white;
    border: 1px solid lightgray;
    padding: 10px;
    margin: 0px 1px 0px 1px;
}

.add-options-container{
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid lightgray;
    padding: 10px;
    margin: 0px 1px 0px 1px;
}

.add-options-content{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.table-icon {
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.chart-container{
    height: 400px;
    width: 100%;
}

