#topbar-landing-container{
    height: 60px;
    width: 100%;
    z-index: 3;
    display: -webkit-flex;
    padding: 10px 0px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #f3f7fd;

    .icon-container img{
        height: 20px;
        width: 20px;
        margin: 5px;
        cursor: pointer;
    }

    .topbar-icon-item-container{
        display: flex;
        align-items: center;
        margin-right: 15px;
    }

    .topbar-icon-item-container svg{
        height: 25px;
        width: 25px;
        cursor: pointer;
    }



}