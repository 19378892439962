.dashboard-topbar-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.key-metrics-container{
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    margin-right: 20px;
    border: 1px solid rgb(242, 242, 242);
    height: 75px;
    width: 200px;

    h1{
        margin: 0px 10px 5px 10px;
        text-align: left;
    }
}

.key-matrics-growth-container{
    display: flex;
    align-items: center;
}

.dashboard-growth-indicator{
    color: green;
    font-size: 12px;
    width: 50px;
    margin-left: 15px;
}

.dashboard-period-selector-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0px 30px 30px 30px;
}

.dashboard-period-selector{
    width: 100px;
    background-color: white;
    border: 1px solid rgb(242, 242, 242);
    border-radius: 5px;
    padding: 0px 10px;

    select{
        background-color: white;
        border: none;
        box-shadow: none;
        margin: 0px;
        padding: 0px;
        font-size: 12px;
    }

    p{
        margin: 5px 0px 0px 4px;
    }
}

#dashboard-outputs-container{
    margin-top: 50px;
}

.dashboard-section-title-container{
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h2{
        text-align: left;
        margin-left: 10px;
    }
}

#dashboard-graph-details-container{
    margin-left: 30px;
    cursor: pointer;
}

#dashboard-graph-details-text{
    color: #2F2C41;
    font-size: 15px;
    margin: 3px 0px 5px 5px;

}

#dashboard-output-detail-compagny-text{
    cursor: pointer;
    color: #616161;
    font-size: 15px;
}

#dashboard-outputs-detail-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.dashboard-output-details-item-container{
    width: 50%;
}

.select-activity-container{
    display: flex;
    align-items: center;
    overflow-x: auto;
  }

  .select-activity-inner-container {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    margin: 20px 0px 20px 0px;
    display: flex;
}

  .select-activity-item-container{
    padding: 5px 10px;
    cursor: pointer;
    border-right: 1px solid #f4f4f4;
    display: flex;
    align-items: center;
  }