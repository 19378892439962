.add-new-indicator-container{
    background-color: white;
    padding: 20px;
    border: 1px solid lightgray;
    margin: 0px 1px 0px 1px;
}

#edit-matrix-container{
    overflow-x: scroll;
}

#matrix-grid-container {
    display: grid;
    grid-template-columns: 2.5fr repeat(5, 0.5fr); /* 2.5fr for the row title, 0.5fr for each column */
    gap: 10px; /* Space between cells */
}

.matrix-grid {
    display: contents; /* Allows columns to span correctly */
}

.matrix-grid-header, .matrix-grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.matrix-grid-header input, .matrix-grid-item input {
    text-align: center;
    width: 30px;
    border: none;
    box-shadow: none;
}

.matrix-delete-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.matrix-grid-row {
    display: contents; /* This makes each row element follow the grid of the parent container */
}

.add-matrix-container{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
    margin: 20px 10px;
}

.button-container-matrix-question{
    margin-top: 10px;
}

.matrix-row-item-container{
    display: flex;
    align-items: center;
    width: 100%;
}

.matrix-row-item-container img{
    width: 15px;
    cursor: pointer;
}

.matrix-row-item-container input{
    width: 150px;
    box-shadow: none;
    border: none;
}

.matrix-column-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 30px;
}

.matrix-column-title-container{
    margin-left: 150px;
    display: flex;
    justify-content: space-between;
}

.matrix-column-item-container{
    display: flex;
    align-items: center;
}

.matrix-column-item-container input{
    box-shadow: none;
    border: none;
    text-align: center;
}

.matrix-column-item-container img{
    width: 15px;
    cursor: pointer;
}

.add-matrix-item-container{
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.add-matrix-item-container img{
    cursor: pointer;
    height: 20px;
    margin-top: 5px;
}

#matrix-column-input{
    width: 100%
}