h1{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 30px;
    color: #2F2C41;
    text-align: center;
    margin: 30px 20px;
  }
  
  h2{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 20px;
    color: #2F2C41;
  }
  
  h3{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    color: #2F2C41;
    font-weight: bold;
  }
  
  h4{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
    color: #2F2C41;
    font-weight: bold;
  }
  
  h5{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 15px;
    color: #616161;
    font-weight: bold;
  }
  
  p{
    font-family: 'Poppins', sans-serif;
    color: #616161;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-align: left;
  }
  
  div{
    font-family: 'Poppins', sans-serif;
    color: #616161;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-align: left;
  }
  
  a{
    font-family: 'Poppins', sans-serif;
    color: #616161;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-decoration: none;
  }
  
  ul{
    text-align: left;
  }
  
  li{
    font-family: 'Poppins', sans-serif;
    color: #616161;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  
  text{
    font-family: 'Poppins', sans-serif;
    color: #616161;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  .input-classic, input {
    padding: 15px;
    border-radius: 3px;
    font-size: 15px;
    border: 1px solid #d3d3d3;
    font-family: "Poppins",sans-serif;
    color: #616161;
    width: 90%;
    box-shadow: inset 0 1px 2px #d3d3d3;
    font-weight: 500;
    background-color: white;
}

button{
    padding: 5px;
    border-radius: 10px;
    background-color: #63cadc;
    border: 1px solid #63cadc;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    width: auto;
    font-weight: bold;
}

.button-container{
    margin: 30px 0px;
}

select{
  font-family: 'Poppins', sans-serif;
  color: #616161;
  font-size: 15px;
  letter-spacing: 0.5px;
  text-align: left;
  width: 90%;
  box-shadow: inset 0 1px 2px #d3d3d3;
  font-weight: 500;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  padding: 5px;
  height: 50px;
  margin-bottom: 20px;
}

.cursor{
  cursor: pointer;
}

textarea{
  font-size: 15px;
  border: 1px solid #d3d3d3;
  font-family: "Poppins",sans-serif;
  color: #616161;
}