#housestyle-colors-container{
    display: flex;
    justify-content: space-between;
}

#housestyle-colors-container input{
    width: 20px;
    height: 20px;
    padding: 0px;
    box-shadow: none;
}